// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acto-animal-js": () => import("./../../../src/pages/acto/animal.js" /* webpackChunkName: "component---src-pages-acto-animal-js" */),
  "component---src-pages-acto-atardecer-js": () => import("./../../../src/pages/acto/atardecer.js" /* webpackChunkName: "component---src-pages-acto-atardecer-js" */),
  "component---src-pages-acto-canto-js": () => import("./../../../src/pages/acto/canto.js" /* webpackChunkName: "component---src-pages-acto-canto-js" */),
  "component---src-pages-acto-hoja-js": () => import("./../../../src/pages/acto/hoja.js" /* webpackChunkName: "component---src-pages-acto-hoja-js" */),
  "component---src-pages-acto-lluvia-js": () => import("./../../../src/pages/acto/lluvia.js" /* webpackChunkName: "component---src-pages-acto-lluvia-js" */),
  "component---src-pages-acto-noche-js": () => import("./../../../src/pages/acto/noche.js" /* webpackChunkName: "component---src-pages-acto-noche-js" */),
  "component---src-pages-acto-pajaro-js": () => import("./../../../src/pages/acto/pajaro.js" /* webpackChunkName: "component---src-pages-acto-pajaro-js" */),
  "component---src-pages-acto-sendero-js": () => import("./../../../src/pages/acto/sendero.js" /* webpackChunkName: "component---src-pages-acto-sendero-js" */),
  "component---src-pages-acto-viento-js": () => import("./../../../src/pages/acto/viento.js" /* webpackChunkName: "component---src-pages-acto-viento-js" */),
  "component---src-pages-advertencia-js": () => import("./../../../src/pages/advertencia.js" /* webpackChunkName: "component---src-pages-advertencia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-va-a-venir-el-tiempo-js": () => import("./../../../src/pages/va-a-venir-el-tiempo.js" /* webpackChunkName: "component---src-pages-va-a-venir-el-tiempo-js" */)
}

